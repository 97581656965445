import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import PrevNext from '../components/prevnext'
//import { graphql } from 'gatsby'
import { graphql, Link } from 'gatsby'
/*import Img from 'gatsby-image'*/
import { FaCalendar } from "react-icons/fa/"
import { FaUser } from "react-icons/fa/"
import SEO from '../components/SEO'
import config from '../config/config'
import Share from '../components/share'
import { kebabCase } from 'lodash'


const ArticleTemplate = (props) => {

    const { markdownRemark: post } = props.data
    const { prev, next } = props.pageContext
    //console.log(prev)
    let tags = post.frontmatter.tags 
        return (
            <Layout>
                <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt || ''}
                image={post.frontmatter.hero.childImageSharp.fluid.src}
                pathname={'/article/'+post.frontmatter.slug}
                article
                />
                

                { post.frontmatter.showHero==='yes' && 
                <Banner bgImage={post.frontmatter.hero.childImageSharp.fluid} 
                title={post.frontmatter.title}
                author={post.frontmatter.author}
                date={post.frontmatter.date}
                description={post.frontmatter.description}

                image={config.siteUrl + post.frontmatter.hero.childImageSharp.fluid.src}
                url={config.siteUrl +'/article/'+ post.frontmatter.slug}
                twitterUsername={config.twitterUsername}
                hashTag={`${config.hashTag}`}
                />
                }
                <div>
                    {/* testing gatsby-image i this div <img src="../img/flavor_wheel.jpg" />*/}
                    {/*<Img fluid={props.data.heroImage.childImageSharp.fluid} />*/}
                    {/* post.frontmatter.hero && <Img fluid={post.frontmatter.hero.childImageSharp.fluid} />*/}
                </div>    
                <div id="main" className="alt">
                    <section id="one">
                        <div className="inner">
                            { post.frontmatter.showHero!=='yes' && 
                            <div>
                            <header className="major">
                                <h1>{post.frontmatter.title}</h1>
                            </header>
                            <div className="content">
                            <ul style={{listStyle:'none',marginLeft:'-1.5em',fontSize:'0.8em'}}>
                                <li style={{display:'inline-block'}}><FaUser size={12} /> {post.frontmatter.author}</li>
                                <li style={{display:'inline-block'}}><FaCalendar size={12} /> {post.frontmatter.date}</li>
                            </ul>
                            </div>
                            <span style={{margin:'0 0 20px -10px',display:'flex'}}>
                            <Share 
                            url={config.siteUrl +'/article/'+ post.frontmatter.slug}
                            title={`${post.frontmatter.title} - ${config.siteTitle}`}
                            image={config.siteUrl + post.frontmatter.hero.childImageSharp.fluid.src}
                            twitterUsername={config.twitterUsername}
                            hashTag={`${config.hashTag}`}
                            /> 
                            </span>
                            </div>
                            }

                          
                            <div dangerouslySetInnerHTML={{__html:post.html}}></div>



                            {tags && tags.length ? (
                            <div style={{ marginTop: `4rem` }}>
                                <ul className="taglist" style={{listStyle:'none',marginLeft:'-1.5em'}}>
                                <li style={{display:'inline-block'}} >More:</li>
                                {tags.map(tag => (
                                    <li style={{display:'inline-block'}} key={tag + `tag`}>
                                    <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            ) : null}

                            
                            <span style={{margin:'0 0 20px 0px',display:'flex'}}>
                            <Share 
                            url={config.siteUrl +'/article/'+ post.frontmatter.slug}
                            title={`${post.frontmatter.title} - ${config.siteTitle}`}
                            image={config.siteUrl + post.frontmatter.hero.childImageSharp.fluid.src}
                            twitterUsername={config.twitterUsername}
                            hashTag={`${config.hashTag}`}
                            /> 
                            </span>
                            <PrevNext prev={prev && prev.node} next={next && next.node} />
                        </div>
                    </section>
                </div>

            </Layout>
        )
    
}

export default ArticleTemplate


export const pageQuery = graphql`
    query ArticleByPath($id : String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                slug
                templateKey
                title
                date(formatString: "MMMM DD, YYYY")
                author
                description
                tags
                showHero
                hero {
                    childImageSharp{
                        fluid (maxWidth:1800, quality:80){
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                    publicURL
                }
            }
            
        }


        #heroImage:file(relativePath:{eq:"flavor_wheel.jpg"}){
        #    childImageSharp{
        #      fluid(maxWidth:1000){
        #        ...GatsbyImageSharpFluid
        #      }
        #      
        #    }
        #}
    }
`


