import React from 'react'
import { FaAngleDoubleLeft,FaAngleDoubleRight } from "react-icons/fa/"
import { Link } from 'gatsby'


const PrevNext = (props) => {

    const { prev, next } = props
    
    return (
        <div style={{display:'flex'}}>
        <ul style={{listStyle:'none',width:'100%',display:'block'}}>
            {prev && <li className="PrevLink">
                <Link to={'/'+prev.frontmatter.templateKey +'/'+ prev.frontmatter.slug}>
                <FaAngleDoubleLeft size={16}/> {" "}
                {prev.frontmatter.title}
                </Link>
            </li>}
            {next && <li className="NextLink">
            <Link to={'/'+next.frontmatter.templateKey +'/'+ next.frontmatter.slug} >
            {next.frontmatter.title}{" "}  <FaAngleDoubleRight size={16}/>
            </Link>
            </li>}
        </ul><br />
        </div>
    )
}

export default PrevNext;